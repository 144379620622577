@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");

/* overwrite bootstrap before importing */
$link-decoration: none;
$primary: #43a2ab;
$secondary: #8f9397;
$success: #66be79;
$warning: #ffc107;
$info: #94e5f2;
$danger: #e2456e;

/* ensure all pages have Bootstrap CSS */
@import "~bootstrap/scss/bootstrap";

// map vars to css vars
:root {
  --h1-size: 2.5rem;
  --h2-size: 2rem;
  --h3-size: 1.75rem;
  --h4-size: 1.5rem;
  --h5-size: 1.25rem;
  --h6-size: 1rem;

  --c-primary: #{$primary};
  --c-secondary: #{$secondary};
  --c-not-so-light: rgb(245, 245, 245);
  --c-light: rgb(238, 238, 238);
  --c-light-active: rgb(219, 217, 217);
  --c-dark-active: rgb(90, 90, 90);
  --c-dark: rgb(71, 71, 71);
  --c-darker: rgb(51, 51, 51);
  --c-muted: #6c757d;
  --c-success: #{$success};
  --c-warning: #{$warning};
  --c-info: #{$info};
  --c-danger: #{$danger};

  --hr-color: var(--c-dark);
  --link-color: var(--c-primary);

  --section-v-padding: 70px;
  --main-min-height: 600px;

  --animation-duration: 0.3s;
  --animation-timing: ease;

  --bg-body-color: var(--c-not-so-light);
  --bg-color: var(--c-light);
  --bg-color-active: var(--c-light-active);
  --text-main-color: var(--c-dark);
  --image-filter: none;

  // specials
  --plane-starting-position: -100%;
}
/* dark theme */
[data-theme="dark"] {
  --bg-body-color: var(--c-darker);
  --bg-color: var(--c-dark);
  --bg-color-active: var(--c-dark-active);
  --text-main-color: var(--c-light);
  --image-filter: grayscale(50%);

  --c-muted: #aabaab;
  --hr-color: var(--c-light);
}

/* xl points */
@media screen and (max-width: 1200px) {
}
/* lg points */
@media screen and (max-width: 992px) {
  :root {
    --plane-starting-position: -150%;
  }
}
/* md points */
@media screen and (max-width: 768px) {
  :root {
    --h1-size: 2rem;
    --h2-size: 1.75rem;
    --h3-size: 1.5rem;
    --h4-size: 1.25rem;

    --section-v-padding: 35px;
    --main-min-height: 80vh;
  }
}
/* sm points */
@media screen and (max-width: 576px) {
  :root {
    --plane-starting-position: -260%;
  }
}

body {
  transition: color var(--animation-duration) var(--animation-timing),
    background-color var(--animation-duration) var(--animation-timing);

  background-color: var(--bg-body-color);
  color: var(--text-main-color);
}

body,
html {
  overflow-x: hidden;
}

main {
  min-height: var(--main-min-height);
}

h1 {
  font-size: var(--h1-size);
}
h2 {
  font-size: var(--h2-size);
}
h3 {
  font-size: var(--h3-size);
}
h4 {
  font-size: var(--h4-size);
}
h5 {
  font-size: var(--h5-size);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
}

.btn {
  line-height: 1;
  padding: 0.75rem;
}

.btn-link.clean,
a.clean {
  color: inherit;
}

a:hover {
  color: var(--c-primary);
}

.btn:not(.dropdown-toggle):not(.btn-link) {
  min-width: 100px;
}

section {
  padding: var(--section-v-padding) 0;
}

hr {
  background-color: var(--hr-color);
}

.logo-inserts {
  fill: var(--bg-body-color);
}

.header .logo-inserts {
  fill: var(--bg-color);
}

.layout-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* in dark mode make images darker */
img:not([src*=".svg"]) {
  object-fit: cover;
  filter: var(--image-filter);
}

.custom-select {
  background-color: var(--bg-color);
  color: var(--text-main-color);
}

/* utils */

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pe-none {
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* BS hacks */
.text-muted {
  color: var(--c-muted) !important;
}

.text-primary {
  color: var(--c-primary) !important;
}

.form-control,
.custom-select {
  font-size: inherit;
}

.modal-content,
.list-group-item,
.card {
  background-color: var(--bg-color);
}

.list-group-item.active,
.list-group-item-primary.list-group-item-action.active,
.list-group-item-warning.list-group-item-action.active,
.list-group-item-danger.list-group-item-action.active {
  background-color: var(--bg-color-active);
  color: inherit;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.close,
.list-group-item-action,
.list-group-item,
.toast-header,
.close:hover,
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: inherit;
}

.progress {
  background-color: #fff;
}

.img-fluid {
  width: 100%;
  max-width: 320px;
}

.bg-light {
  background-color: var(--bg-color) !important;
}

.bg-active {
  background-color: var(--bg-color-active) !important;
}

.toast {
  background-color: var(--bg-color);
  border-color: var(--c-muted);
}
.toast-container {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 9999; // to be visible on modals

  display: flex;
  overflow-y: auto;
  height: 100vh;
  &__inner {
    margin-top: auto;
  }
}

/* add container xxl */
.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  max-width: 1320px;
}

/* all modals will be fullscreen on md devices */
@media screen and (max-width: 768px) {
  .modal-dialog {
    margin: 0;
  }
  .modal-content {
    height: 100vh;
  }
}

.react-datepicker__navigation-icon::before {
  border-color: $primary !important;
  top: 10px !important;
}

// crunches for swiper
.swiper-slide {
  display: flex;
  align-items: center;
}
