.welcome {
  height: 90vh;
  min-height: 700px;
  max-height: 1080px;
  position: relative;
  overflow: hidden;
  background: url(../assets/landing/banner.jpg) center no-repeat;
  background-size: cover;
}

.welcome-content {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.welcome-content-group {
  max-width: 720px;
  text-align: center;
  padding: 0.5em 1.5em;
  color: #474747;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 2;
}
@media (max-width: 768px) {
  .welcome-content-group {
    margin-top: -20vh;
  }
}

.welcome-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: url(../assets/landing/banner-blured.jpg) center no-repeat;
  background-size: cover;
  z-index: 0;
  opacity: 0;
  pointer-events: none;
}

@media (max-width: 1200px) {
  .welcome,
  .welcome-overlay {
    background-position-x: 80%;
  }
}

.showcase-img {
  filter: var(--image-filter);
}

[data-theme="dark"] .welcome {
  opacity: 0.9;
}

.timeline {
  position: relative;
  padding: 0;
  list-style: none;
}

.timeline:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  width: 2px;
  margin-left: -1.5px;
  content: "";
  background-color: var(--secondary);
}

.timeline > li {
  position: relative;
  min-height: 50px;
  margin-bottom: 50px;
}

.timeline > li:after,
.timeline > li:before {
  display: table;
  content: " ";
}

.timeline > li:after {
  clear: both;
}

.timeline > li .timeline-panel {
  position: relative;
  float: right;
  width: 100%;
  padding: 0 20px 0 100px;
  text-align: left;
}

.timeline > li .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline > li .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline > li .timeline-image {
  position: absolute;
  z-index: 100;
  left: 0;
  width: 80px;
  height: 80px;
  margin-left: 0;
  text-align: center;
  color: white;
  border: 7px solid #64c5ce;
  border-radius: 100%;
  background-color: white;
}

.timeline > li .timeline-image img {
  height: 100%;
  object-fit: cover;
}

.timeline > li .timeline-image h2 {
  font-size: 18px;
  line-height: 14px;
  margin-top: 24px;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
  padding: 0 20px 0 100px;
  text-align: left;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline > li:last-child {
  margin-bottom: 0;
}

.timeline .timeline-heading h4 {
  margin-top: 0;
  color: inherit;
}

.timeline .timeline-heading h4.subheading {
  text-transform: none;
}

.timeline .timeline-body > ul,
.timeline .timeline-body > p {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .timeline:before {
    left: 50%;
  }

  .timeline > li {
    min-height: 100px;
    margin-bottom: 100px;
  }

  .timeline > li .timeline-panel {
    float: left;
    width: 41%;
    padding: 0 20px 20px 30px;
    text-align: right;
  }

  .timeline > li .timeline-image {
    left: 50%;
    width: 100px;
    height: 100px;
    margin-left: -50px;
  }

  .timeline > li .timeline-image h2 {
    font-size: 20px;
    line-height: 18px;
    margin-top: 28px;
  }

  .timeline > li.timeline-inverted > .timeline-panel {
    float: right;
    padding: 0 30px 20px 20px;
    text-align: left;
  }
}

@media (min-width: 992px) {
  .timeline > li {
    min-height: 150px;
  }

  .timeline > li .timeline-panel {
    padding: 0 20px 20px;
  }

  .timeline > li .timeline-image {
    width: 150px;
    height: 150px;
    margin-left: -75px;
  }

  .timeline > li .timeline-image h2 {
    font-size: 22px;
    line-height: 26px;
    margin-top: 50px;
  }

  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 20px 20px;
  }
}

@media (min-width: 1200px) {
  .timeline > li {
    min-height: 170px;
  }

  .timeline > li .timeline-panel {
    padding: 0 20px 20px 70px;
  }

  .timeline > li .timeline-image {
    width: 170px;
    height: 170px;
    margin-left: -85px;
  }

  .timeline > li .timeline-image h2 {
    margin-top: 65px;
  }

  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 70px 20px 20px;
  }
}

.showcase .showcase-text {
  padding: 3rem;
}

.showcase .showcase-img {
  min-height: 30rem;
  background-size: cover;
}

@media (min-width: 768px) {
  .showcase .showcase-text {
    padding: 7rem;
  }
}
